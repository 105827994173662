import { Component } from '@angular/core';
import { EncryptDecryptService } from './utils/encrypt-decrypt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'events';

  constructor(
    private encryptDecryptService: EncryptDecryptService,
  ) {}

  ngOnInit(): void {

  }

}
