import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { AxService } from 'src/app/services/ax.service';
import { LoginTypesVip } from 'src/app/utils/types';

@Component({
  selector: 'app-point-information',
  templateUrl: './point-information.component.html',
  styleUrls: ['./point-information.component.css']
})
export class PointInformationComponent implements OnInit {

  public user$!: Observable<LoginTypesVip>

  public isLoggedIn!:boolean;

  public isCustomHeader = false;

  public userName: string | undefined;
  public noClient: string | undefined;
  public dataPointHistory: any = []
  public TotalPoint = 0
  public CountData = 0
  

  constructor(
    private auth: AuthService,
    private service: AxService,
  ) { }

  ngOnInit(): void {
    this.auth.login$.subscribe((r:any) => {
      if (r) {
        this.userName = r[0]?.client_name
        this.noClient = r[0]?.no_client
        // console.log(r[0]?.client_name)
        this.getdataPointHistory(r[0]?.no_client)
        // this.getdataPointHistory("CK999999")
      }
    })
  }

  getdataPointHistory(params:any) {
    const data = this.service.getpointhistory(params).subscribe((r) => {
      if (r.status === 200) {
        this.dataPointHistory = r.data.data
        this.TotalPoint = r.data.totalPoints
        this.CountData = r.data.length
      }
    }, (err) => {})
  }

}
