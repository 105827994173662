import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AxService } from 'src/app/services/ax.service';
import { LoginTypesVip } from 'src/app/utils/types';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-change-data',
  templateUrl: './change-data.component.html',
  styleUrls: ['./change-data.component.css']
})
export class ChangeDataComponent implements OnInit {

  public formCustomer!: FormGroup<any>;

  public user$!: Observable<LoginTypesVip>

  public isLoggedIn!:boolean;

  public isCustomHeader = false;

  public userName = ""
  public noClient = ""

  isLoading: boolean = false;
  errorResult: string | undefined;
  successResult: string | undefined;

  constructor(
    private service: AxService,
    private fb: FormBuilder,
    private auth: AuthService,
  ) {

  }

  ngOnInit(): void {
    this.auth.login$.subscribe((r:any) => {
      if (r) {
        this.userName = r[0]?.client_name
        this.noClient = r[0]?.no_client
        this.initForm(r[0])
      }
    })
  }

  initForm(data:any) {
    this.formCustomer = this.fb.group({
      alamat: [data.address1],
      province: [data.province],
      kodepos: [data.zipcode],
      email: [data.email],
      phone: [data.hp],
      no_client: [data.no_client],
    })
  }

  phonesCheck(p: string) {
    p = String(p).trim();
    const phone = p.charAt(0) + p.charAt(1);
    if (phone === '62') {
      return '+' + p;
    } else if (p.charAt(0) === '0') {
      return p.replace('0', '+62');
    } else if (p) {
      return '+62' + p;
    } else {
      return p.replace(/[- .]/g, '');
    }
  }

  onSaveData(){
    const data = this.formCustomer.value
    if (data.phone == '' || data.phone == undefined || data.phone == null) {
      this.errorResult = "Phone is required"
      this.successResult = ""
    } else if(data.email == '' || data.email == undefined || data.email == null) {
      this.errorResult = "Email is required"
      this.successResult = ""
    }else {
      this.successResult = "Success update data"
      this.isLoading = true
      this.service.updatedatacustvip(data).subscribe((r) => {
        if (r.status === 200) {
          this.isLoading = false
        }
        if (r.status === 404) {
          this.isLoading = false
          this.errorResult = r.message
        }
      }, (e) => {
        this.isLoading = false
        this.errorResult = "koneksi internet Anda."
        console.error(e)
      })
    }
  }


}
