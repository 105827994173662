import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoginTypesVip } from 'src/app/utils/types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public user$!: Observable<LoginTypesVip>

  public isLoggedIn!:boolean;

  public isCustomHeader = false;

  public userName = ""

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.auth.login$.subscribe((r:any) => {
      if (r) {
        this.userName = r[0]?.client_name
      }
    })
  }


  


}
