import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppEncryptDecrypt {
  // private key = CryptoJS.enc.Utf8.parse();
  private iv = CryptoJS.enc.Utf8.parse(`12099131989152`);
  constructor() { }
  // Methods for the encrypt and decrypt Using AES
  encryptUsingAES256(text: string): any {
    var encrypted = CryptoJS.DES.encrypt(text, environment.key.toString());
    return encrypted.toString();
  }
  decryptUsingAES256(decString: string) {
    // console.log(decString)
    var decrypted = CryptoJS.DES.decrypt(decString, environment.key.toString());
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
