import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, takeUntil } from "rxjs";
import { LoginTypesVip } from '../utils/types';
import { environment } from "src/environments/environment";
import { AppConstant } from "../utils/constant";
import { Router } from "@angular/router";

export interface LoginModel {
  company: string;
  phone: string;
  password: string;
}


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private destroy$ = new Subject<void>();

  private auth$ = new BehaviorSubject<LoginTypesVip>(Object())
  public login$!: Observable<LoginTypesVip>
  public error$ = new BehaviorSubject<any>(Object())

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    var session: any = JSON.parse(localStorage.getItem(AppConstant.APP_LOCAL_STORAGE_KEY)!)
    this.auth$ = new BehaviorSubject<LoginTypesVip>(session)
    this.login$ = this.auth$.asObservable()
  }

  private onDestroySubscription() {
    this.destroy$.next()
    this.destroy$.complete()
    console.warn('OnDestroy Completed!')
  }


  public signIn(body: LoginModel) {
    this.http.post<LoginTypesVip>(`${environment.apiUrl}vip/authvip`, body).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      const data: LoginTypesVip = res.data
      localStorage.setItem(AppConstant.APP_LOCAL_STORAGE_KEY, JSON.stringify(data))
      this.auth$.next(data)

      setTimeout(() => {
        this.onDestroySubscription()
      }, 2000);
    }, (error) => {
      const err: any = true
      this.error$.next(error)
    })
  }

  public get loginStatus(): LoginTypesVip {
    return this.auth$.value;
  }

  public get loginError(): Observable<any> {
    return this.error$.value;
  }

  public signOut() {
    localStorage.removeItem(AppConstant.APP_LOCAL_STORAGE_KEY)
    this.auth$.next(null!)
    this.router.navigate(['/'], { replaceUrl: true })
  }
}
