
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, shareReplay, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { EncryptDecryptService } from './encrypt-decrypt.service';

const basicAuthToken = 'eyJhbGciOiJIUzI1NiIsInR5cCsI6IkpXVCJ9.eyJlbWFpbCI6Imhhbm55LmJhbmdhbkBtYWlsLmNvbSIsImlhdCI6MTY3NDA5NzcxNiwiZXhwIjoxNjc0MTg0MTE2fQ.ov168Wd3yVu02W8wAG-SHP5SEBu074n4uDgx1Lof8yQ'

@Injectable()
export class CustomappInterceptor implements HttpInterceptor {
   express_base_url = environment.apiUrl;
   private cache = new Map<string, any>();
   constructor(
      private encryptDecryptService: EncryptDecryptService,
      private http: HttpClient,
      @Inject(PLATFORM_ID) private platformId: Object,
      private router: Router

      ) {}


   appAuthToken: string = ''

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      if (request.url !== 'https://ipapi.co/json') {
         // console.warn('IPAPI')
         request = request.clone({
            headers: new HttpHeaders({
              'x-access-token': localStorage.getItem(environment.auth_token) ? localStorage.getItem(environment.auth_token)! : basicAuthToken
            })
         })
      }

      const cachedResponse = this.cache.get(request.url);
      if (cachedResponse) {
         of(cachedResponse);
      }

      return next.handle(request)
          .pipe(
              map(res => {
                  // console.log("Passed through the interceptor in response");
                  return res
              }),
              catchError((error: HttpErrorResponse) => {
                  if (error.status === 500) {
                     if (error.error.name === 'TokenExpiredError' || error.error.name === 'JsonWebTokenError') {
                        if (isPlatformBrowser(this.platformId)) {
                           this.http.post(`${this.express_base_url}eventscustomer/refresh_token`, {session: this.encryptDecryptService.encryptUsingAES256(window.localStorage.getItem('cp')!)}).subscribe((r:any) => {
                              console.log(r.data)
                              // localStorage.clear()
                              localStorage.setItem(environment.auth_token, r.data)
                              this.appAuthToken = r.data
                              setTimeout(() => {
                                 // this.router.navigate(['/'], {replaceUrl: true})
                                 window.location.reload()
                              }, 1000);
                           })
                        }
                     }
                  }
                  return throwError(`Error: ${error.error.message}`);
              })
          )
   }
}
