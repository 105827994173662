import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { CustomHttpMetaResponseModel, CustomHttpResponseModel } from '../models/response_model';
import { environment } from 'src/environments/environment';
import { AlertService } from '../utils/widgets/alert/alert.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class AxService {

  constructor(
    private http: HttpClient,
    private alert: AlertService
  ) {
  }

  public changepassowrd(jsonData: any):Observable<any> {
    return this.http.post(`${environment.apiUrl}vip/changepassword`, jsonData, { headers: headers });
  }

  public updatedatacustvip(jsonData: any):Observable<any> {
    return this.http.post(`${environment.apiUrl}vip/updatevipmember`, jsonData, { headers: headers });
  }

  public getpointhistory(params: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}vip/vipmember/${params}`, { headers: headers })
  }

  public savedatacust(jsonData: any):Observable<any> {
    return this.http.post(`${environment.apiUrl}events/savedregistrasi`, jsonData, { headers: headers });
  }

  public getprovince(): Observable<any> {
    return this.http.get(`${environment.apiUrl}province`, { headers: headers });
  }

  public getcity(prov: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}province/city/${prov}`, { headers: headers })
  }

  public getref(): Observable<any> {
    return this.http.get(`${environment.apiUrl}events/axdoc`, { headers: headers })
  }

  public getgifts(refid: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}events/axgo_gift/${refid}`, { headers: headers })
  }

}
