import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomappInterceptor } from './utils/app.interceptor';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { UrlService } from './services/url.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GiftComponent } from './pages/gift/gift.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PointInformationComponent } from './pages/point-information/point-information.component';
import { ChangeDataComponent } from './pages/change-data/change-data.component';
import { ChangePinComponent } from './pages/change-pin/change-pin.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    HomeComponent,
    GiftComponent,
    SignInComponent,
    DashboardComponent,
    PointInformationComponent,
    ChangeDataComponent,
    ChangePinComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    FormsModule, // Add FormsModule here
  ],
  providers: [
    UrlService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomappInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
