<app-header></app-header>

<div class="col-12 col-md-12">
  <div class="container-fluid">
    <section class="container">
      <div class="row py-lg-4">
        <div class="col-lg-12 col-md-12 p-0">
          <div class="card border-0">
            <div class="card-header">
                <h2>UBAH DATA PRIBADI<br>
                    (Update Personal Details)
                </h2>
            </div>
            <div class="card-body">
                <form [formGroup]="formCustomer">
                    <div class="row pt-3">
                        <div class="col-12 col-md-6 text-start">
                            <div class="col-6 col-md-6 text-start pt-2 pb-2">
                                <span style="font-size: 11px!important;">Alamat Surat Menyurat</span><br>
                                <span style="font-size: 11px!important;"><i>(Mailing Address)</i></span>
                                <input type="text" formControlName="alamat" class="form-control mt-2 mb-2" id="alamat" placeholder="alamat">
                            </div>
                            <div class="col-6 col-md-6 text-start pt-2 pb-2">                            
                                <span style="font-size: 11px!important;">Provinsi</span><br>
                                <span style="font-size: 11px!important;"><i>(Province)</i></span>
                                <input type="text" formControlName="province" class="form-control mt-2 mb-2" id="province" placeholder="province">
                            </div>
                            <div class="col-6 col-md-6 text-start pt-2 pb-2">
                                <span style="font-size: 11px!important;">Kode Pos</span><br>
                                <span style="font-size: 11px!important;"><i>(Postal Code)</i></span>
                                <input type="text" formControlName="kodepos" class="form-control mt-2 mb-2" id="kodepos" placeholder="kodepos">
                            </div>
                            <div class="col-6 col-md-6 text-start pt-2 pb-2">                            
                                <span style="font-size: 11px!important;">Nomor Telepon Genggam</span><br>
                                <span style="font-size: 11px!important;"><i>(Phone Number)</i></span>
                                <input type="text" formControlName="phone" class="form-control mt-2 mb-2" id="phone" placeholder="phone">
                            </div>
                            <div class="col-6 col-md-6 text-start pt-2 pb-2">
                                <span style="font-size: 11px!important;">Email</span><br>
                                <span style="font-size: 11px!important;"><i>(Email)</i></span>
                                <input type="text" formControlName="email" class="form-control mt-2 mb-2" id="email" placeholder="email">
                            </div>
                            <div *ngIf="errorResult" class="pt-2 pb-2">
                                <small class="error-message" style="font-size: 10px !important; color:red;">{{errorResult}}</small>
                            </div>
                            <div *ngIf="successResult" class="pt-2 pb-2">
                              <small class="error-message" style="font-size: 10px !important; color:green;">{{successResult}}</small>
                            </div>
                            <div class="col-6 col-md-6 text-start pt-2 pb-2">                           
                                <ng-container *ngIf="isLoading;else onFilters">
                                    <button class="btn btn-dark" type="button" disabled>
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                  </ng-container>
                                  <ng-template #onFilters>
                                    <button type="button" class="btn btn-dark btn-sm rounded-pill col-12"  (click)="onSaveData()">Submit</button>
                                  </ng-template>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
