// import * as moment from 'moment';

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelpers {

  momentjs: any = moment;
  year: any;
  yearShort: any;
  month: any;
  day: any;
  DateTime: any;
  DateTimeSets: any;
  constructor() {
    this.year = this.momentjs().format('YYYY');
    this.yearShort = this.momentjs().format('YY');
    this.month = this.momentjs().format('MM');
    this.day = this.momentjs().format('DD');
    this.DateTime = this.momentjs().format('YYYY-MM-DD h:mm:ss');
    this.DateTimeSets = this.momentjs().format('YYYY-MM-DD h:mm');
  }

  /**
   * @param {date|moment} start The start date
   * @param {date|moment} end The end date
   * @param {string} type The range type. eg: 'days', 'hours' etc
   */
  getRangeDate(startDate: string, endDate: string, type: 'days') {
    var dates = [];
    startDate = moment(startDate).format('YYYY-MM-DD')
    endDate = moment(endDate).format('YYYY-MM-DD')

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()).format('YYYY-MM-DD'));
    }

    dates.unshift(startDate)
    dates.push(endDate)
    return dates;
  }

  addHours(date: string, count:number, format: 'hours' | 'minutes' | 'seconds'): string {
    const today = this.momentjs().format('YYYY-MM-DD')
    const hour = this.momentjs(`${today} ${date}`).add(count, format).format('HH:mm')
    return hour
  }

  getHours(time: string): string {
    const today = this.momentjs().format('YYYY-MM-DD')
    return this.momentjs(`${today} ${time}`).hours() + ' Jam'
  }

  startMonth(): string {
    return this.momentjs().startOf('month').format('YYYY-MM-DD');
  }

  endMonth(): string {
    return this.momentjs().endOf('month').format('YYYY-MM-DD');
  }

  formatDate(date: Date): string {
    return this.momentjs(date).format('YYYY-MM-DD');
  }

  formatMonth(date: Date, format: string = 'YYYY-MM'): string {
    return this.momentjs(date).format(format);
  }

  formatNewDate(date: string): string {
    return this.momentjs(date).format('YYYY-MM-DD');
  }

  formatDateTime(dateTime: Date, format = 'YYYY-MM-DD hh:mm:ss'): string {
    return this.momentjs(dateTime).format(format);
  }

  getDate() {
    return this.day + '-' + this.month + '-' + this.year;
  }

  toDay(format?:string) {
    if (format) return this.momentjs().format(format);
    return this.momentjs().format('YYYY-MM-DD');
  }

  startOfMonth(date: any, format = 'DD-MM-YYYY') {
    return this.momentjs(date).startOf('month').format(format);
  }

  endOfMonth(date: any, format = 'DD-MM-YYYY') {
    return this.momentjs(date).endOf('month').format(format);
  }

  formatDay(date: Date): string {
    return this.momentjs(date).format('DD');
  }

  formatDayText(date: string) {
    return this.momentjs(date).format('DD MMMM');
  }

  formatTime(val: string) {
    return this.momentjs(val,"hh:mm:ss").format('HH:mm')
  }

  addDays(days: number) {
    const today = this.momentjs().format('YYYY-MM-DD')
    return this.momentjs(today, "YYYY-MM-DD").add(days, "days").format('YYYY-MM-DD')
  }

  // convert
  public convertMinuteToHours(totalMinutes:number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }
}
