import { Injectable } from '@angular/core';

export interface YearsList {
  id: number
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() { }
  public getListOfYears() {
    var max = new Date().getFullYear()
    var min = max - 20
    var years: YearsList[] = []
    var id = 1
    for (var i = max; i >= min; i--) {
      years.push({ id: id, name: i.toString() })
      id++
    }
    return new Promise((resolve, rejects) => {
      resolve(years)
    })
  }
  // get random string
  getRandomString(length: number) {
    let result = '';
    const characters = Date.now()+'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getUserDeviceIsMobile() {
    return new Promise<boolean>((resolve, reject) => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }

  truncate(str: string, n: number) {
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
  };

  getFileExtension(filename: string) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
  }

  queryParams(data: any) {
    var str = [];
    for (var p in data)
      if (data.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
      }
    return str.join("&");
  }

  dataURItoBlob(dataURI:any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  // DataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',');
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  //   const ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++) {
  //     ia[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([ia], { type: mimeString });
  // }

  public abjadList(index: number, txtFormat='upper') {
    const abjad = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    if (txtFormat !== 'upper') {
      return abjad[index].toLowerCase()
    }
    return abjad[index].toUpperCase()
  }

  clearCacheStorage(): Promise<Promise<boolean>[][]> {
    return caches.keys().then(cacheKeys =>
      Promise.all(
        cacheKeys.map(cacheKey => {
          const ngswRegex = /^(ngsw).*/;
          if (ngswRegex.test(cacheKey)) {
            return caches
              .open(cacheKey)
              .then(cache => cache.keys().then(requests => requests.map(req => cache.delete(req))));
          }
          return caches
          .open(cacheKey)
          .then(cache => cache.keys().then(requests => requests.map(req => cache.delete(req))));
        })
      )
    );
  }

  public padTo2Digits(num:any) {
    return num.toString().padStart(2, '0');
  }
}
