import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true
})

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: true
})


export interface Toast {
  title?: string;
  type: 'success' | 'error' | 'warning' | 'info' | 'question';
  text?: string,
  confirmButtonText?: string,
  timer?: number
  showBtnCancel?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alert: boolean = false;

  constructor() { }

  setAlert(alert: boolean) {
    this.alert = alert;
  }

  getAlert(): boolean {
    return this.alert;
  }

  public async showToast(data: Toast) {
    await Toast.fire({
      icon: data.type,
      title: data.title,
      timer: data.timer ? 2000 : data.timer
    })
  }

  public async showConfirmAlert(data: Toast) {
    return Swal.fire({
      title: data.title,
      text: data.text,
      icon: data.type,
      showCancelButton: data.showBtnCancel === undefined ? true : data.showBtnCancel,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: data.confirmButtonText
    })
  }

  public async showAlert(data: Toast) {
    Swal.fire(
      data.title,
      data.text,
      data.type
    )
  }

  public async showPopupAlert(data: Toast) {
    return swalWithBootstrapButtons.fire({
      title: data.title,
      text: data.text,
      icon: data.type,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: data.confirmButtonText,
      confirmButtonColor: '#00B074',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    })
  }


}
