<app-header></app-header>

<div class="col-12 col-md-12">
  <div class="container-fluid">
    <section class="container">
      <div class="row py-lg-4">
        <div class="col-lg-12 col-md-12 p-0">
          <div class="card border-0">
            <div class="card-header">
                <h2>INFORMASI POIN<br>
                    (Points Information)</h2>
            </div>
            <div class="card-body text-center">
                <div class="table-responsive tb-container">
                    <table class="table">
                    <thead>
                        <tr>
                            <th class="col">NOMOR NOTA <br><span class="en">(Receipt Number)</span></th>
                            <th class="col">TANGGAL <br><span class="en">(Transaction Date)</span></th>
                            <th scope="col">JUMLAH TRANSAKSI <br><span class="en">(Transaction Amount)</span></th>
                            <th scope="col">POIN TERTUKAR <br><span class="en">(Point Redeemed)</span></th>
                            <th scope="col">POIN DIDAPAT <br><span class="en">(Number of points earned)</span></th>
                            <th scope="col">TOTAL POIN <br><span class="en">(Total Points)</span></th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let r of dataPointHistory; let i = index">
                            <td>{{r.no_inv}}</td>
                            <td>{{r.dt_inv | date : "dd MMM yyy" }}</td>
                            <td>{{r.amount | currency : ' ' : 'symbol' : '1.0'}}</td>
                            <td>{{r.pointreedem}}</td>
                            <td>{{r.pointsearned}}</td>
                            <td>{{r.totalpoint}}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><b>Total</b></td>
                            <td>{{TotalPoint}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
