import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AxService } from 'src/app/services/ax.service';
import { UrlService } from 'src/app/services/url.service';
import { AppMessage } from 'src/app/utils/appMessage';
import { DateHelpers } from 'src/app/utils/date.helper';
import { HelperService } from 'src/app/utils/helpers';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppEncryptDecrypt } from 'src/app/utils/decryptencrypt';
import { AuthService, LoginModel } from 'src/app/services/auth.service';
import { AppConstant } from 'src/app/utils/constant';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit, AfterViewInit {

  public bottomSignin = false

  public formGroups!: FormGroup<any>;
  public formForgot!: FormGroup<any>;

  public errorMessages = AppMessage.errorMessages;
  alerts: any[] = [];
  isLoading = false;

  

  constructor(
    private modal: BsModalService,
    private dateHelper: DateHelpers,
    private service: AxService,
    private helper: HelperService,
    private login: AuthService,
    private router: Router,
    private encrypt: AppEncryptDecrypt,
    private formBuilder: FormBuilder,
    private url: UrlService,
    private activeRoute: ActivatedRoute,
  ) {

  }
  ngOnInit(): void {
    this.initFormSign()
    this.initFormForgotPin()
    this.login.error$.subscribe((err) => {
      if (err) {
        this.isLoading = false
        this.alerts.push({
          type: 'danger',
          msg: 'Incorrect Phone or Password!',
          timeout: 1500
        })
      }
    })

    this.login.login$.subscribe((r:any) => {
      this.alerts = []
      if (r) {
        this.isLoading = false
        const body: LoginModel = this.formGroups.value
        this.isLoading = false
        this.alerts.push({
          type: 'success',
          msg: 'Welcome ' + r[0]?.client_name,
          timeout: 1500
        })
        localStorage.removeItem(AppConstant.APP_LOCAL_STORAGE_REMEMBER_KEY)
        this.router.navigate(['/dashboard'], { replaceUrl: true }).then((_) => {
          window.location.reload()
        })
      }
    })
  }

  initFormForgotPin() {
    this.formForgot = this.formBuilder.group({
      phones: ['', [
        Validators.required, Validators.pattern(new RegExp('[0-9_-]{10,13}'))
      ]],
      fullname: ['', Validators.required]
    })
  }

  initFormSign() {
    this.formGroups = this.formBuilder.group({
      phone: ['', [
        Validators.required, Validators.pattern(new RegExp('[0-9_-]{10,13}'))
      ]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      company: ['AX'],
    })
  }

  ChangeBottom(){
    this.formGroups.reset();
    this.formForgot.reset();
    this.bottomSignin = !this.bottomSignin
  }

  // signIn(){

  //   console.log('sign in')
  // }

  // forgotPin(){
  //   console.log('reset password')
  // }



  ngAfterViewInit(): void {
    const remember = localStorage.getItem(AppConstant.APP_LOCAL_STORAGE_REMEMBER_KEY)
    if (remember) {
      const parse: LoginModel = JSON.parse(this.encrypt.decryptUsingAES256(remember))
      if (parse) {
          this.formGroups.controls['phone'].setValue(parse.phone)
          this.formGroups.controls['password'].setValue(parse.password)
      }
    }
  }

  onSubmit() {
    this.isLoading = true
    const body: LoginModel = this.formGroups.value
    setTimeout(() => {
      this.login.signIn({ phone: this.phonesCheck(body.phone) , password: body.password , company: 'AX' })
    }, 2000);
  }

  phonesCheck(p: string) {
    p = String(p).trim();
    const phone = p.charAt(0) + p.charAt(1);
    if (phone === '62') {
      return '+' + p;
    } else if (p.charAt(0) === '0') {
      return p.replace('0', '+62');
    } else if (p) {
      return '+62' + p;
    } else {
      return p.replace(/[- .]/g, '');
    }
  }

}
