import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AxService } from 'src/app/services/ax.service';
import { UrlService } from 'src/app/services/url.service';
import { AppMessage } from 'src/app/utils/appMessage';
import { DateHelpers } from 'src/app/utils/date.helper';
import { EncryptDecryptService } from 'src/app/utils/encrypt-decrypt.service';
import { HelperService } from 'src/app/utils/helpers';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.css']
})
export class GiftComponent implements OnInit {

  getdocnumber: string = '';
  vouchergift: string = '';

  constructor(
    private dateHelper: DateHelpers,
    private service: AxService,
    private helper: HelperService,
    private encryptDecryptService: EncryptDecryptService,
    private fb: FormBuilder,
    private url: UrlService,
    private activeRoute: ActivatedRoute,
  ) {
    this.activeRoute.queryParams.subscribe(async (d:any) => {
      this.getdocnumber = d.id;
      this.getvouchers()
    });
  }

  ngOnInit(): void {
  }

  getvouchers(){
    this.service.getgifts(this.getdocnumber).subscribe({
      next: (res) => {
        this.vouchergift = res.data.clientid
      },
      error: (err) => {

      },
      complete: () => {

      }
    });
  }

  onEndGift(){
    this.goToPage('https://events.ptkcg.co.id/')
  }

  goToPage(link: any) {
    this.url.getWindow().open(link, '_self')
  }

}
