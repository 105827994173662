import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoginTypesVip } from 'src/app/utils/types';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public user$!: Observable<LoginTypesVip>

  public isLoggedIn!:boolean;

  public isCustomHeader = false;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.login$

    if (this.auth.loginStatus) {
      this.isLoggedIn = true
    }

  }
  
  onLogout() {
    this.auth.signOut()
  }

}
