<app-header></app-header>

<div class="col-12 col-md-12 mx-auto py-5 text-center">
  <div class="container-fluid">
    <section class="py-5 text-center container">
      <div class="row py-lg-5 mx-auto">
        <div class="col-lg-12 col-md-12 p-0">
          <div class="card border-0">
            <div class="card-body">
              <h1 class="text-center">Welcome To {{ userName }}</h1>
              <h3 class="fs-6 fw-normal text-secondary text-center m-0">
                Privilege Membership
              </h3>
              <img
              src="../../../assets/logo/ARMANI-LOGO.png"
              alt="BootstrapBrain Logo"
              class="col-2"
              />
              <hr>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
