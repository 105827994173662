<div class="col-12 col-md-12 mx-auto py-5 text-center">
  <div class="container-fluid">
    <section class="py-5 text-center container">
      <div class="row py-lg-5 mx-auto">
        <div class="col-lg-8 col-md-8 p-0">
          <div class="card">
            <div class="card-body">
              <img
                src="../../../assets/img/banner-login.jpg"
                class="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-4 p-0 d-flex align-items-center justify-content-center"
        >
          <!-- <div class="card border-light-subtle shadow-sm"> </div> -->
          <div class="col-12 col-lg-11 col-xl-10">
            <div class="card-body p-3 p-md-4 p-xl-5">
              <!-- LOGIN -->
              <div *ngIf="!bottomSignin">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-5">
                      <div class="text-center mb-4">
                        <a href="#!">
                          <img
                            src="../../../assets/logo/kcg-logo.webp"
                            alt="BootstrapBrain Logo"
                            class="col-4"
                          />
                        </a>
                      </div>
                      <h2 class="h4 text-center">Sign In</h2>
                      <h3 class="fs-6 fw-normal text-secondary text-center m-0">
                        Privilege Membership
                      </h3>
                    </div>
                  </div>
                </div>
                <div *ngFor="let alert of alerts">
                  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
                </div>
                <form [formGroup]="formGroups" (ngSubmit)="onSubmit()">
                  <div class="row gy-3 overflow-hidden">
                    <div class="col-12">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          name="phone"
                          id="phone"
                          placeholder="0813XXXX"
                          formControlName="phone"
                          required
                        />
                        <label for="phone" class="form-label"
                          >Enter Your Mobile Phone Number</label
                        >
                        <div *ngFor="let error of errorMessages.phone">
                          <ng-container
                            *ngIf="formGroups.get('phone')!.hasError(error.type) && (formGroups.get('phone')!.dirty || formGroups.get('phone')!.touched)">
                            <small class="text-danger" style="font-size: 10px !important;">{{error.message}}</small>
                          </ng-container>
                        </div>
                      </div>
                      <div class="form-floating mb-3">
                        <input
                          type="password"
                          class="form-control"
                          name="password"
                          id="password"
                          placeholder="Enter Your Pin"
                          formControlName="password"
                          required
                        />
                        <label for="password" class="form-label"
                          >Enter Your Pin</label
                        >
                        <div *ngFor="let error of errorMessages.password">
                          <ng-container
                            *ngIf="formGroups.get('password')!.hasError(error.type) && (formGroups.get('password')!.dirty || formGroups.get('password')!.touched)">
                            <div class="form-text"><small class="text-danger">{{error.message}}</small></div>
                          </ng-container>
                        </div>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <ng-container *ngIf="isLoading; else Login">
                          <div class="d-grid d-block">
                              <button type="submit" class="btn btn-primary float-end" [disabled]="true">
                                  <div class="spinner-border text-dark" role="status" style="height: 15px;width: 15px;margin-right: 5px;">
                                      <span class="visually-hidden"></span>
                                  </div>
                                  Loading...
                              </button>
                          </div>
                      </ng-container>
                      <ng-template #Login>
                          <button type="submit" class="btn btn-dark btn-lg" [disabled]="!formGroups.valid">Sign In</button>
                      </ng-template>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <!-- RESET -->
              <div *ngIf="bottomSignin">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-5">
                      <div class="text-center mb-4">
                        <a href="#!">
                          <img
                            src="../../../assets/logo/kcg-logo.webp"
                            alt="BootstrapBrain Logo"
                            class="col-4"
                          />
                        </a>
                      </div>
                      <h2 class="h4 text-center">Password Reset</h2>
                      <h3 class="fs-6 fw-normal text-secondary text-center m-0">
                        Privilege Membership
                      </h3>
                    </div>
                  </div>
                </div>
                <form action="#!">
                  <div class="row gy-3 overflow-hidden">
                    <div class="col-12">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          name="fullname"
                          id="fullname"
                          placeholder="Enter Your Name"
                          required
                        />
                        <label for="fullname" class="form-label"
                          >Enter Your Name</label
                        >
                      </div>
                      <div class="form-floating mb-3">
                        <input
                          type="password"
                          class="form-control"
                          name="phones"
                          id="phones"
                          placeholder="Enter Your Phone Number"
                          required
                        />
                        <label for="phones" class="form-label"
                          >Enter Your Phone Number</label
                        >
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="d-grid">
                        <button class="btn btn-dark btn-lg" type="submit">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="row">
                <div class="col-12">
                  <div
                    class="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5"
                  >
                  <!-- (click)="ChangeBottom()" -->
                    <a
                      href="#!"
                      class="link-secondary text-decoration-none"
                      
                      >Login</a
                    >
                    <!-- <a
                      href="#!"
                      class="link-secondary text-decoration-none"
                      (click)="ChangeBottom()"
                      >Forgot Pin</a
                    > -->
                    <a
                    href="https://wa.me/628119107818"
                    class="link-secondary text-decoration-none"
                    target="_blank">Contact Us</a
                  >
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
