import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AxService } from 'src/app/services/ax.service';
import { UrlService } from 'src/app/services/url.service';
import { AppMessage } from 'src/app/utils/appMessage';
import { DateHelpers } from 'src/app/utils/date.helper';
import { EncryptDecryptService } from 'src/app/utils/encrypt-decrypt.service';
import { HelperService } from 'src/app/utils/helpers';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public form!: FormGroup<any>;
  public errorMessages = AppMessage.errorMessages;

  errorResultevents: string | undefined;

  errorResultGender: string | undefined;
  errorResultName: string | undefined;
  errorResultCountry: string | undefined;
  errorResultEmail: string | undefined;
  errorResultPhone: string | undefined;
  errorResultDateOfBirth: string | undefined;
  errorResultPrivacyPolicy: string | undefined;
  errorMessageSave: string | undefined;

  isLoading: boolean = false;

  disablecity = false

  public datayears: any = [];
  public datamonth = [
    { id: 1,value: '01', name: 'January' },
    { id: 2,value: '02', name: 'February' },
    { id: 3,value: '03', name: 'March' },
    { id: 4,value: '04', name: 'April' },
    { id: 5,value: '05', name: 'May' },
    { id: 6,value: '06', name: 'June' },
    { id: 7,value: '07', name: 'July' },
    { id: 8,value: '08', name: 'August' },
    { id: 9,value: '09', name: 'September' },
    { id: 10,value: '10', name: 'October' },
    { id: 11,value: '11', name: 'November' },
    { id: 12,value: '12', name: 'December' }
  ];

  public datatanggal: any = [];
  public datagender = [
    { id: 1, value: 'F', name: 'Ms' },
    { id: 2, value: 'M', name: 'Mr' },
    { id: 3, value: 'N', name: 'I’d prefer not to say' }
  ];

  public dataoptions = [
    { id: 1, value: 'Y', name: 'Yes' },
    { id: 2, value: 'N', name: 'No' }
  ];

  public datafollowingchanel = [
    { id: 1, value: 'E-mail', name: 'E-mail' },
    { id: 2, value: 'Telephone', name: 'Telephone' },
    { id: 3, value: 'SMS/MMS', name: 'SMS/MMS' },
    { id: 4, value: 'Chat and Social Networks', name: 'Chat and Social Networks' }
  ];

  public dataprovince: any = [];
  public datacity: any = [];

  refdocnumber: string | undefined;

  modalRef: BsModalRef = new BsModalRef();
  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-xl',
  };

  constructor(
    private modal: BsModalService,
    private dateHelper: DateHelpers,
    private service: AxService,
    private helper: HelperService,
    private encryptDecryptService: EncryptDecryptService,
    private fb: FormBuilder,
    private url: UrlService,
    private activeRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.initForm()
    this.getListOfYears()
    this.getListTanggal()
    this.getProvince()
    this.getDocReff()
  }

  openActionModal(template: TemplateRef<any>, data?: any) {
    this.modalRef = this.modal.show(template, {
      class: 'modal-xl',
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  getProvince() {
    const data = this.service.getprovince().subscribe((r) => {
      if (r.status === 200) {
        this.dataprovince = r.data
      }
    }, (err) => {})
  }

  getDocReff() {
    const data = this.service.getref().subscribe((r) => {
      if (r.status === 200) {
        this.refdocnumber = r.data
      }
    }, (err) => {})
  }

  initForm() {
    this.form = this.fb.group({
      gender: [''],
      firstname: [''],
      lastname: [''],
      companyname: [''],
      companytitle: [''],
      country: [''],
      email: ['', Validators.required],
      phone: ['', [
        Validators.required, Validators.pattern(new RegExp('[0-9_-]{10,13}'))
      ]],
      tanggal: ['0', Validators.required],
      bulan: ['0', Validators.required],
      tahun: ['0', Validators.required],
      address: [''],
      province: ['0'],
      city: ['0'],
      zipcode: [''],
      sendme_communications: [''],
      following_channels: [''],
      purposes_of_analysing: [''],
    })
  }

  phonesCheck(p: string) {
    p = String(p).trim();
    const phone = p.charAt(0) + p.charAt(1);
    if (phone === '62') {
      return '+' + p;
    } else if (p.charAt(0) === '0') {
      return p.replace('0', '+62');
    } else if (p) {
      return '+62' + p;
    } else {
      return p.replace(/[- .]/g, '');
    }
  }

  getListOfYears() {
    var max = 2006;
    var min = max - 51;
    var id = 1;
    for (var i = max; i >= min; i--) {
      this.datayears.push({ id: id, name: i.toString() });
      id++;
    }
  }

  getListTanggal() {
    for (let i = 1; i < 31; i++) {
      this.datatanggal.push({id: i, name: i.toString() })
    }
  }

  goToPage(link: any) {
    this.url.getWindow().open(link, '_self')
  }

  onChangeProv(ev:any){
    const parem = ev.target.value === 0 || ev.target.value === '0' ? undefined : ev.target.value;
    const data = this.service.getcity(parem).subscribe((r) => {
      if (r.status === 200) {
        this.disablecity = true
        this.datacity = r.data
      }
    }, (err) => {})
  }

  onSaveData(){
    const data = this.form.value
    if (data.gender == '' || data.gender == undefined || data.gender == null) {
        this.errorResultGender = "Gender is required"
    }else if(data.firstname == '' || data.firstname == undefined || data.firstname == null){
        this.errorResultName = "Gender"
    }else if(data.country == '' || data.country == undefined || data.country == null){
        this.errorResultCountry = "Country / Region of Residenc is required"
    } else if(data.email == '' || data.email == undefined || data.email == null){
      this.errorResultEmail = "Email is required"
    } else if(data.phone == '' || data.phone == undefined || data.phone == null){
      this.errorResultPhone = "Phone is required"
    } else if(data.tanggal == '' || data.tanggal == undefined || data.tanggal == null){
      this.errorResultDateOfBirth = "Date is required"
    } else if(data.bulan == '' || data.bulan == undefined || data.bulan == null){
      this.errorResultDateOfBirth = "Month is required"
    } else if(data.tahun == '' || data.tahun == undefined || data.tahun == null){
      this.errorResultDateOfBirth = "Year is required"
    } else if(data.sendme_communications == '' || data.sendme_communications == undefined || data.sendme_communications == null){
      this.errorResultPrivacyPolicy = "Privacy Policy is required"
    } else {
      this.getDocReff() // check kembali document number
      const savedata = {
        gender:data.gender,
        clientid: this.refdocnumber,
        company: "AX",
        fullname:data.firstname+' '+data.lastname,
        companyname:data.companyname,
        companytitle:data.companytitle,
        companycountry:data.country,
        email:data.email,
        phone:this.phonesCheck(data.phone),
        dateofbirth:data.tanggal+'-'+data.bulan+'-'+data.tahun,
        address: data.address,
        province: data.province,
        city: data.city,
        zipcode: data.zipcode,
        sendme: data.sendme_communications,
        following_channels: data.following_channels,
        purposes_analysing: data.purposes_of_analysing,
      }

      this.isLoading = true
      this.service.savedatacust(savedata).subscribe((r) => {
        if (r.status === 200) {
          this.isLoading = false
          this.goToPage('gift?id='+r.data.refid)
        }
        if (r.status === 404) {
          this.isLoading = false
          this.errorMessageSave = r.message
        }
      }, (e) => {
        this.isLoading = false
        this.errorMessageSave = "koneksi internet Anda."
        console.error(e)
      })


    }
  }

}
