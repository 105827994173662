import { PointInformationComponent } from './pages/point-information/point-information.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { GiftComponent } from './pages/gift/gift.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './services/auth.guard';
import { ChangeDataComponent } from './pages/change-data/change-data.component';
import { ChangePinComponent } from './pages/change-pin/change-pin.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SignInComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      title: 'home',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'point-information',
    component: PointInformationComponent,
    data: {
      title: 'Point Information',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'change-data',
    component: ChangeDataComponent,
    data: {
      title: 'Change Data Personal',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'change-pin',
    component: ChangePinComponent,
    data: {
      title: 'Change Pin',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'home',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'gift',
    component: GiftComponent,
    data: {
      title: 'Page Gift',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    component: PageNotFoundComponent,
    data: {
      title: 'Page not Found',
      customLayout: false
    }
  },
  { path: '**', redirectTo: '/error' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
