<!-- Navbar Start -->
<nav class="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
  <a href="/" class="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5">
      <img src="https://i0.wp.com/ptkcg.co.id/wp-content/uploads/2019/10/cropped-logo.png?fit=1947%2C488&ssl=1" class="m-0" alt="" width="150"/>
  </a>
  <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"></span>
  </button>

  <ng-container *ngIf="isCustomHeader;else Headers">

  </ng-container>

  <ng-template #Headers>
      <ng-container *ngIf="user$ | async as user; else notSignIn">
          <div class="collapse navbar-collapse" id="navbarCollapse">
              <div class="navbar-nav ms-auto p-4 p-lg-0 me-3">
                  <a [routerLink]="['/point-information']" class="nav-item nav-link" routerlinkactive="active"><b>Point Information</b></a>
                  <a [routerLink]="['/change-data']" class="nav-item nav-link" routerlinkactive="active"><b>Change Personal Data</b></a>
                  <a [routerLink]="['/change-pin']" routerlinkactive="active" class="nav-item nav-link"><b>Change PIN</b></a>
              </div>
              <a (click)="onLogout()" class="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block"><b>Logout</b><i class="fa fa-user ms-3"></i>
              </a>
          </div>
      </ng-container>
      <ng-template #notSignIn>
          <div class="collapse navbar-collapse" id="navbarCollapse">
              <div class="navbar-nav ms-auto p-4 p-lg-0">
                <a [routerLink]="['/point-information']" class="nav-item nav-link" routerlinkactive="active">Point Information</a>
                <a [routerLink]="['/change-data']" class="nav-item nav-link" routerlinkactive="active">Change Personal Data</a>
                <a [routerLink]="['/change-pin']" routerlinkactive="active" class="nav-item nav-link">Change PIN</a>
              </div>
              <a [routerLink]="['/']" class="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">Sign In<i class="fa fa-arrow-right ms-3"></i>
              </a>
          </div>
      </ng-template>
  </ng-template>
</nav>
<!-- Navbar End -->
