import { Injectable } from '@angular/core';


export class AppMessage {

  // form registrasi message
   static errorMessages = {
    password: [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 6 characters' },
    ],
    nama: [
      { type: 'required', message: 'Nama Lengkap is required' },
    ],
    first_name: [
      { type: 'required', message: 'First Name is required' },
    ],
    last_name: [
      { type: 'required', message: 'Last Name is required' },
    ],
    emergencyContact: [
      { type: 'required', message: 'Emergency Contact is required' },
    ],
    address: [
      { type: 'required', message: 'Address is required' },
    ],
    pekerjaan: [
      { type: 'required', message: 'Pekerjaan is required' },
    ],
    jurusan: [
      { type: 'required', message: 'Jurusan is required' },
    ],
    jabatan: [
      { type: 'required', message: 'Jabatan is required' },
    ],
    perusahan: [
      { type: 'required', message: 'Perusahan is required' },
    ],
    status_nikah: [
      { type: 'required', message: 'Marriage Status is required' },
    ],
    nama_ayah: [
      { type: 'required', message: 'Father`s Name is required' },
    ],
    nama_ibu: [
      { type: 'required', message: 'Mother`s Name is required' },
    ],
    name: [
      { type: 'required', message: 'Fullname is required' },
    ],
    nama_sekolah: [
      { type: 'required', message: 'School Name is required' },
    ],
    city: [
      { type: 'required', message: 'City is required' },
    ],
    lang: [{ type: 'required', message: 'Languange is required' }],
    dob: [
      { type: 'required', message: 'Date of birth is required' },
    ],
    pob: [
      { type: 'required', message: 'Place of birth is required' },
    ],
    sim: [
      { type: 'required', message: 'SIM is required' },
    ],
    alamat_ktp: [
      { type: 'required', message: 'Alamat sesuai KTP is required' },
    ],
    phone: [
      { type: 'required', message: 'Phone number is required' },
      { type: 'pattern', message: 'Please enter a valid Phone number (ex. 0857XXXXXX)' }
    ],
    phone_ayah: [
      { type: 'required', message: 'Phone number is required' },
      { type: 'pattern', message: 'Please enter a valid Phone number (ex. 0857XXXXXX)' }
    ],
    phone_ibu: [
      { type: 'required', message: 'Phone number is required' },
      { type: 'pattern', message: 'Please enter a valid Phone number (ex. 0857XXXXXX)' }
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    KTP: [
      { type: 'required', message: 'KTP is required' },
      { type: 'pattern', message: 'Please enter a valid ID Card Number' },
      { type: 'maxlength', message: 'No. KTP harus 16 digit' },
      { type: 'minlength', message: 'No. KTP harus 16 digit' },
    ],
    no_telepon_1: [
      { type: 'required', message: 'Phone is required' },
      { type: 'pattern', message: 'Please enter a valid Telephone number (ex. 0857XXXXXX) or ([kode Wilayah]XXXXXX))' }
    ],
    no_telepon_2: [
      { type: 'required', message: 'Phone is required' },
      { type: 'pattern', message: 'Please enter a valid Telephone number (ex. 0857XXXXXX) or ([kode Wilayah]XXXXXX)' }
    ],
    ktp: [
      { type: 'required', message: 'No KTP is required' },
    ],
    alamat_sekarang: [
      { type: 'required', message: 'Alamat Sekarang is required' },
    ],
    position_apply: [
      { type: 'required', message: 'Position Apply is required' },
    ],
    adverstising: [
      { type: 'required', message: 'Adverstising is required' },
    ],
    sex: [
      { type: 'required', message: 'Sex is required' },
    ],
    Gender: [
      { type: 'required', message: 'Jenis Kelamin is required' },
    ],
    Province: [
      { type: 'required', message: 'Provinsi is required' },
    ],
    isStore: [
      { type: 'required', message: 'Pilih toko yang kamu suka' },
    ],
    City: [
      { type: 'required', message: 'Kota Asal is required' },
    ],
    birthdate: [
      { type: 'required', message: 'Tanggal Lahir is required' },
    ],
    birthplace: [
      { type: 'required', message: 'Tempat Lahir is required' },
    ],
    Hobby: [
      { type: 'required', message: 'Hobby is required' },
    ],
    LastEdu: [
      { type: 'required', message: 'Pendidikan Terakhir is required' },
    ],
    EduStatus: [
      { type: 'required', message: 'Status Pendidikan is required' },
    ],
    graduateyear: [
      { type: 'required', message: 'Tahun Angkatan is required' },
    ],
    height: [
      { type: 'required', message: 'Tinggi Badan is required' },
      { type: 'min', message: 'Min 159' },
      { type: 'max', message: 'Max 190' },
    ],
    weight: [
      { type: 'required', message: 'Berat badan is required' },
      { type: 'min', message: 'Min 50' },
      { type: 'max', message: 'Max 90' },
    ],
    gaji_bersih_1: [
      { type: 'required', message: 'Required!' },
      { type: 'minlength', message: 'Minimal 1 Jt' }
    ],
    gaji_bersih_2: [
      { type: 'required', message: 'Required!' },
      { type: 'minlength', message: 'Minimal 1 Jt' }
    ],
    gaji_bersih_3: [
      { type: 'required', message: 'Required!' },
      { type: 'minlength', message: 'Minimal 1 Jt' }
    ],
    gaji_yg_diharapkan: [
      { type: 'required', message: 'Required!' },
      { type: 'minlength', message: 'Minimal 1 Jt' }
    ],
    isgrooming: [
      { type: 'required', message: 'Grooming is required' },
    ],
    University: [
      { type: 'required', message: 'Universitas is required' },
    ],
    Major: [
      { type: 'required', message: 'Jurusan is required' },
    ],
    isillhistory: [
      { type: 'required', message: 'Riwayat Kesehatan is required' },
    ],
    isTatoo: [
      { type: 'required', message: 'Tatto is required' },
    ],
    tugas_dan_tanggung_jawab_1: [
      { type: 'required', message: 'Field is required' },
      { type: 'minlength', message: 'Must be at least 300 characters' },
      { type: 'maxlength', message: 'Max 300 characters' },
    ],
    tugas_dan_tanggung_jawab_2: [
      { type: 'required', message: 'Field is required' },
      { type: 'minlength', message: 'Must be at least 300 characters' },
      { type: 'maxlength', message: 'Max 300 characters' },
    ],
    tugas_dan_tanggung_jawab_3: [
      { type: 'required', message: 'Field is required' },
      { type: 'minlength', message: 'Must be at least 300 characters' },
      { type: 'maxlength', message: 'Max 300 characters' },
    ],
    tugas_dan_tanggung_jawab_4: [
      { type: 'required', message: 'Field is required' },
      { type: 'minlength', message: 'Must be at least 300 characters' },
      { type: 'maxlength', message: 'Max 300 characters' },
    ],
  };
  static fields: any;

  constructor() { }
}
