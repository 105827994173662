<div class="col-12 col-md-8 mx-auto px-2 text-center">
  <div class="container-fluid">
    <div class="row" style="place-content: center;">
      <div class="col-12 col-md-12">
        <img src="../../../assets/logo/kcg.png" class="img-fluid col-5 col-md-4" alt="...">
        <h2><b>Client ID</b> {{vouchergift}}</h2>
        <div class="p-5">
          <img src="../../../assets/img/AX-Gift.png" class="img-fluid col-4 col-md-2" alt="...">
        </div>
        <div class="p-2">
          <h1><b style="font-size: 15px !important;">Thank you for your submission.</b></h1>
        </div>
        <div class="p-1">
          <p>Rest assured, your data is secure with us.<br>
            Please bring your phone to our store, or present a <br>
            screenshot of this page, to receive our special gifts.</p>
        </div>
        <div class="p-5">
          <button type="button" class="btn btn-dark btn-sm rounded-pill col-8 col-md-4"  (click)="onEndGift()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
