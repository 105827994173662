import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AxService } from 'src/app/services/ax.service';
import { UrlService } from 'src/app/services/url.service';
import { AppMessage } from 'src/app/utils/appMessage';
import { DateHelpers } from 'src/app/utils/date.helper';
import { EncryptDecryptService } from 'src/app/utils/encrypt-decrypt.service';
import { HelperService } from 'src/app/utils/helpers';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginTypesVip } from 'src/app/utils/types';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.css']
})
export class ChangePinComponent implements OnInit {

  public formChangePin!: FormGroup<any>;

  public user$!: Observable<LoginTypesVip>

  public isLoggedIn!:boolean;

  public isCustomHeader = false;
  public errorMessages = AppMessage.errorMessages;

  public userName = ""
  public noClient = ""

  isLoading: boolean = false;
  errorResult: string | undefined;
  successResult: string | undefined;

  constructor(
    private modal: BsModalService,
    private dateHelper: DateHelpers,
    private service: AxService,
    private helper: HelperService,
    private encryptDecryptService: EncryptDecryptService,
    private fb: FormBuilder,
    private url: UrlService,
    private activeRoute: ActivatedRoute,
    private auth: AuthService,
  ) {

  }

  ngOnInit(): void {
 
    this.auth.login$.subscribe((r:any) => {
      if (r) {
        this.userName = r[0]?.client_name
        this.noClient = r[0]?.no_client
        this.initForm(r[0])
      }
    })

  }

  initForm(data:any) {
    this.formChangePin = this.fb.group({
      noclient: [data.no_client],
      phone: [data.hp],
      passwordold: [''],
      passwordkonfirmasi: [''],
      passwordnew: ['', [Validators.required, Validators.minLength(6)]],
      company: ['AX'],
    })
  }

  phonesCheck(p: string) {
    p = String(p).trim();
    const phone = p.charAt(0) + p.charAt(1);
    if (phone === '62') {
      return '+' + p;
    } else if (p.charAt(0) === '0') {
      return p.replace('0', '+62');
    } else if (p) {
      return '+62' + p;
    } else {
      return p.replace(/[- .]/g, '');
    }
  }
  
  isValid6DigitNumber(password: any) {
    return /^\d{6}$/.test(password);
  }

  // Function to validate a 6-character alphanumeric password
  isValid6CharAlphanumeric(password: any) {
    return /^[A-Z0-9]{6}$/.test(password);
  }

  onSaveData(){
    const data = this.formChangePin.value
    if (!this.isValid6CharAlphanumeric(data.passwordnew)) {
       this.errorResult = "Your PIN must be 6 characters long"
    } else if(data.passwordold == '' || data.passwordold == undefined || data.passwordold == null) {
        this.errorResult = "Password Old is required"   
    } else if(data.passwordnew == '' || data.passwordnew == undefined || data.passwordnew == null) {
        this.errorResult = "Password New is required"   
    } else if(data.passwordkonfirmasi == '' || data.passwordkonfirmasi == undefined || data.passwordkonfirmasi == null) {
      this.errorResult = "Password Konfirmasi is required"   
    } else if(data.passwordnew !== data.passwordkonfirmasi) {
      this.errorResult = "Password Konfirmasi failed"   
    } else {
      this.errorResult = ""
      const resdata = {
        company: "AX",
        pinold: data.passwordold,
        pinnew: data.passwordkonfirmasi,
        phone: data.phone,
        no_client: data.noclient
      }
      this.isLoading = true
      this.service.changepassowrd(resdata).subscribe((r) => {
        if (r.status === 200) {
          this.isLoading = false
          this.successResult = "Success update data"
          this.formChangePin.reset()
        }
        if (r.status === 404) {
          this.isLoading = false
          this.errorResult = r.message
        }
      }, (e) => {
        this.isLoading = false
        this.errorResult = "Please check your PIN"
        console.error(e)
      })
    }
    

  }

}
